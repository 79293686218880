import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { LayoutCoreMessage, LayoutMessageType, RuntimeLayoutChangeActiveScreen, RuntimeLayoutEventSourceType, RuntimeLayoutSnapshot } from '../../models';
import { LogUtils } from '../../utils';
import { BusyService } from '../busy/busy.service';
import { WebSocketClientService } from '../web-socket/web-socket-client.service';
import { ClientAuthService } from './client-auth.service';


@Injectable({
  providedIn: 'root'
})
export class LayoutChangeActiveScreenService {

  constructor(
    private busyService: BusyService,
    private clientAuthService: ClientAuthService,
    private webSocketClientService: WebSocketClientService
  ) { }

  trigger(
    layoutSnapshot: RuntimeLayoutSnapshot,
    activeScreen: number,
  ): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      this.busyService.setBusy(true, 'Sending event to server...');

      const rlcas = this.buildRuntimeLayoutChangeActiveScreen(
        layoutSnapshot,
        activeScreen,
      );
      const msgContent = new RuntimeLayoutChangeActiveScreen(rlcas);
      LogUtils.log('Sending event to server:', msgContent);
      const msgContentBuffer = RuntimeLayoutChangeActiveScreen.encode(msgContent).finish();

      const coreMsg = new LayoutCoreMessage({
        messageSequenceNr: this.webSocketClientService.getSequenceNumber(),
        messageType: LayoutMessageType.LayoutChangeActiveScreen,
        messageContent: msgContentBuffer,
      });
      const coreMsgBuffer  = LayoutCoreMessage.encode(coreMsg).finish();

      // send message but don't expect anything back
      this.webSocketClientService.send(coreMsgBuffer);

      observer.next(true);
      observer.complete();
    });
  }

  private buildRuntimeLayoutChangeActiveScreen(
    layoutSnapshot: RuntimeLayoutSnapshot,
    activeScreen: number,
  ): RuntimeLayoutChangeActiveScreen {
    let eventDateTime = Date.now();
    eventDateTime += (this.clientAuthService.deviceDateTimeMillisecondsDiff || 0);
    return new RuntimeLayoutChangeActiveScreen({
      sourceType: RuntimeLayoutEventSourceType.Active,
      snapshotTick: layoutSnapshot.snapshotTick,
      layoutObjectId: layoutSnapshot.runtimeLayout.objectId,
      layoutScreenObjectId: activeScreen,
      layoutScreenObjectTick: layoutSnapshot.runtimeLayout.layoutScreens[activeScreen].tick,

      eventDateTime: (new Date(eventDateTime)).toISOString(),
    });
  }

}
