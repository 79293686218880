import { DesignStyleJsonItemField } from './design-style-json-item-field.model';

export class DesignStyleJsonItem {

  x: number;
  y: number;
  cols: number;
  rows: number;

  col?: number; // deprecated with v2 (Gridster)
  row?: number; // deprecated with v2 (Gridster)

  itemStyle?: Partial<CSSStyleDeclaration>;
  labelStyle?: Partial<CSSStyleDeclaration>;

  field?: DesignStyleJsonItemField | any;

  valueIfNull?: string;

  constructor(item?: any, versionNumber?: number) {
    Object.assign(this, item);

    this.upgradeToLatestVersion(versionNumber);

    if (this.field && this.field.originalVariableGuidId) {
      this.field.originalVariableGuidId = this.field.originalVariableGuidId.replace(/-/g, '');
    }
  }

  upgradeToLatestVersion(versionNumber?: number) {
    if (versionNumber == null) {
      if (this.field) {
        this.field.originalVariableGuidId = this.field.originalVariableGuidId || this.field.variableGuidId;
        this.field.variablePointer = {
          stateVariableGuidId: this.field.variableGuidId,
          subMemberGuidId: this.field.subVariableMemberGuidId,
        };
      }
    }

    if (versionNumber == null || versionNumber < 2) {
      this.convertToGridster();
    }

    if (versionNumber === 2) {
      // Gridster FTW!!!
    }
  }

  private convertToGridster() {
    delete this.col; // we don't need this anymore with Gridster...
    delete this.row; // we don't need this anymore with Gridster...

    if (this.itemStyle) {
      this.x = parseInt(this.itemStyle.gridColumnStart || '1') - 1;
      this.y = parseInt(this.itemStyle.gridRowStart || '1') - 1;
      this.cols = parseInt(this.itemStyle.gridColumnEnd || '2') - parseInt(this.itemStyle.gridColumnStart || '1');
      this.rows = parseInt(this.itemStyle.gridRowEnd || '2') - parseInt(this.itemStyle.gridRowStart || '1');
    }
  }

}
