import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
  template: '<div></div>'
})
export class BaseComponent implements OnDestroy {

  isDestroyed: boolean;
  subscriptions: Subscription[] = [];

  constructor() { }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    if (this.subscriptions) {
      this.subscriptions
        .forEach((sub: Subscription) => {
          sub.unsubscribe();
        });
      this.subscriptions.length = 0;
    }
  }

}
