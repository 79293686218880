import { ChangeDetectorRef, Input, QueryList, ViewChildren, Directive, Component } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { DictNumber, RuntimeLayoutControlCode, RuntimeLayoutEventContext, RuntimeLayoutScreen, RuntimeLayoutScreenControlCollection } from 'src/app/shared/models';
import { RuntimeLayoutHead, RuntimeLayoutHeadPointer } from 'src/app/shared/models/runtime-layout/head';
import { LogUtils } from 'src/app/shared/utils';
import { ScreenControlContainerComponent } from '../control-container/screen-control-container.component';
import { Observable } from 'rxjs';



@Component({
  template: '<div></div>'
})
export abstract class ScreenBaseComponent extends BaseComponent {

  @ViewChildren(ScreenControlContainerComponent) controlComponents: QueryList<ScreenControlContainerComponent>;

  @Input() set layoutScreen (value: RuntimeLayoutScreen) {
    this._layoutScreen = value;

    this.refresh();
  }
  get layoutScreen(): RuntimeLayoutScreen { return this._layoutScreen; }
  private _layoutScreen: RuntimeLayoutScreen;

  controls: any[];
  forceRefresh: boolean;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    super();
    this.controls = [];
  }

  protected refresh(): void {
    this.forceRefresh = this.controls?.[0]?.[0]?.layoutControlCode === RuntimeLayoutControlCode.InputControl1;

    this.controls = []; // controls is an array of arrays
    this.cdr.markForCheck();

    if (!this.layoutScreen) {
      this.controls.push([]);
      return;
    }

    if (this.forceRefresh) {
      setTimeout(() => {
        this.addControls();
      }, 10);
    } else {
      this.addControls();
    }
  }

  private addControls() {
    for (const sccKey of Object.keys(this.layoutScreen.screenControlCollections) || []) {
      const screenControl = this.layoutScreen.screenControlCollections[sccKey];
      const screenControlControls = this.getControlsForScreenControlCollection(this.layoutScreen, screenControl);
      this.controls.push(screenControlControls);
    }

    this.cdr.markForCheck();
  }

  private getControlsForScreenControlCollection(layoutScreen: RuntimeLayoutScreen, screenControlCollection: RuntimeLayoutScreenControlCollection) {
    const controls = [];

    // head controls
    const heads = (screenControlCollection.headPointers || [])
    .map((hp: RuntimeLayoutHeadPointer) => {
      return layoutScreen.heads[hp.objectId];
    })
    .filter((h: RuntimeLayoutHead) => {
      return h;
    })
    .sort((a, b) => { return (a.headSequenceNr || 0) - (b.headSequenceNr || 0); });
    if (heads.length) {
      controls.push(...heads);
    }

    // active control
    const acp = screenControlCollection.activeControlPointer;
    if (!acp) {
      LogUtils.error('Active control pointer is null...');
      return controls;
    }

    controls.push(layoutScreen.controls[layoutScreen.primaryLayoutControlObjectId]);

    return controls;
  }

  abstract backButtonOverride(): boolean;

  abstract forwardButtonOverride(): boolean;

  abstract getControlsContext(): DictNumber<RuntimeLayoutEventContext>;

  abstract preActionTrigger(): Observable<void>;

}
