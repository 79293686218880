import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Scan } from '../../models';
import { LogUtils } from '../../utils';
import { RingBuffer } from '../../utils/ring-buffer';



@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  ignoreEnterKeyInPrimaryLayoutControl: boolean;
  ignoreScanInPrimaryLayoutControl: boolean;
  private scannerSubject: Subject<Scan>;
  private scanQueue: RingBuffer<Scan>;

  constructor(
    private ngZone: NgZone,
  ) {
    this.scannerSubject = new Subject();

    this.scanQueue = new RingBuffer(10);
  }

  clear(): void {
    if (!this.scanQueue?.length) return;

    LogUtils.log(`Clearing ${this.scanQueue.length} items from the scan queue...`);
    this.scanQueue.length = 0;
  }

  getPendingScan(): Scan {
    if (!this.scanQueue?.length) return null;
      
    const scan = this.scanQueue.splice(0, 1)[0];
    LogUtils.log(`Got pending scan (${this.scanQueue.length} items remaining): `, scan);
    return scan;
  }

  listen(): Observable<Scan> {
    return this.scannerSubject.asObservable();
  }

  emitScan(scan: Scan) {
    if (!scan) return;

    this.ngZone.run(() => {
      if (this.scannerSubject.observers.length) {
        LogUtils.log('Sending scan to listeners: ', scan);
        this.scannerSubject.next(scan);
      } else {
        this.scanQueue.push(scan);
        LogUtils.log(`Adding scan to queue (${this.scanQueue.length} items in queue): `, scan);
      }
    });
  }


}
