import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GridsterComponent, GridsterConfig } from 'angular-gridster2';
import { Observable, Observer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Resource } from 'src/app/shared/models/resource.model';
import { RuntimeLayoutSetData } from 'src/app/shared/models/runtime-layout/data/runtime-layout-set-data.model';
import { DesignStyleJsonItem } from 'src/app/shared/models/studio';
import { BasePlugin } from 'src/app/shared/services';
import { LayoutResourceService } from 'src/app/shared/services/protobuf/layout-resource.service';
import { LogUtils } from 'src/app/shared/utils';
import { BlobUtils } from 'src/app/shared/utils/blob.utils';
import { GuidUtils } from 'src/app/shared/utils/guid.utils';
import { DictNumber, DictString, Notification, RuntimeLayoutData, RuntimeLayoutDesign, RuntimeLayoutDesignStyle, RuntimeLayoutEventContext, RuntimeLayoutEventPlatformObjectType, RuntimeLayoutNotifyType, RuntimeLayoutText, RuntimeLayoutValue, RuntimeLayoutValueType, Scan } from '../../../models';
import { KeyboardType } from '../../../models/keyboard-type.enum';
import { BARCODE_TYPES } from '../../barcode-scanner/barcode-scanner-livestream/barcode-types';
import { KeyboardService } from '../../keyboard';
import { ControlBaseComponent } from '../base/control-base.component';

enum List1DeviceControlUISummaryLocation {
  TopCenter = 0,
  TopRight = 1,
  BottomCenter = 2,
  BottomRight = 3,
}

export enum QuantityList1LineBehaviour {
  Add = 0,
  Remove = 1,
}
export enum QuantityList1ScanBehaviour {
  None = 0,
  QuantityChange = 1,
}

@Component({
  selector: 'lc-control-quantity-list1',
  templateUrl: 'control-quantity-list1.component.html',
  styleUrls: ['./control-quantity-list1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlQuantityList1Component extends ControlBaseComponent implements OnInit {

  readonly barcodeTypes = BARCODE_TYPES;
  readonly defaultScreenCols = 1; // only really used for old designJsons who didn't have .screenCols
  readonly defaultScreenRows = 5; // only really used for old designJsons who didn't have .screenRows

  @ViewChildren(GridsterComponent) gridsterComponents: QueryList<GridsterComponent>;

  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutTexts: DictNumber<RuntimeLayoutText>;

  activeKeyboardForData: RuntimeLayoutData;
  private clickedData: RuntimeLayoutData;
  designStyleMappings: any[];
  headerDefinition: RuntimeLayoutDesignStyle;
  headerAddToIndex: number;

  private filtersDefinition: any;
  private activeFilter: any;

  private sortsDefinition: any;
  private activeSort: any;
  gridsterOptions1: GridsterConfig;
  gridsterOptions2: GridsterConfig;
  ignoreButtonOverride: boolean;
  lineBehaviour: QuantityList1LineBehaviour;
  listDefinition: any;
  listData: RuntimeLayoutData[];
  listReady: boolean;
  private numOfVisibleItems: number;
  private originalBackButton: boolean;
  private originalButtonsType: number;
  private originalForwardButton: boolean;
  pipePureValueBusting: number = 0;
  quantityScanBehaviour: QuantityList1ScanBehaviour;
  quantityScanEnabled: boolean;
  resourceMap: DictString<string> = {};
  rowDefinition: RuntimeLayoutDesignStyle[];
  rowMinHeight: string;;

  private setComplexValueName: string;
  private setComplexTriggerValueName: string;
  private setDatasObjectPointers: RuntimeLayoutSetData[];
  thisComponent: ControlQuantityList1Component;
  uiGroupingMap: DictNumber<{ value: string, designStyleGuidId: string }>;
  uiSummaryLocation: string;
  uiSummaryValue: number;
  updateContextArray: any[];
  useUpdate: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private el: ElementRef,
    private keyboardService: KeyboardService,
    private layoutResourceService: LayoutResourceService,
  ) {
    super(injector);

    this.gridsterOptions1 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 0,
      mobileBreakpoint: 0,
    };
    this.gridsterOptions2 = {
      displayGrid: 'none',
      gridType: 'fit',
      margin: 2,
      mobileBreakpoint: 0,
    };

    this.thisComponent = this;
  }

  ngOnInit() {
    this.clickedData = undefined;
    this.headerDefinition = undefined;
    this.listData = [];

    if (!this.layoutControl || !this.layoutScreen) {
      this.showMissingFieldNotification('LayoutScreen/LayoutControl');
      return;
    }

    this.originalBackButton = this.layoutScreen.backButton;
    this.originalForwardButton = this.layoutScreen.forwardButton;
    this.originalButtonsType = this.layoutScreen.parseRV('ButtonsType', 0);

    // Get filter definition and default filter
    this.filtersDefinition = JSON.parse(this.layoutControl.parseRV('FilterListJson', null));
    this.activeFilter = this.getDefaultFilter();

    // Get sort definition and default sort
    this.sortsDefinition = JSON.parse(this.layoutControl.parseRV('SortListJson', null));
    this.activeSort = this.getDefaultSort();

    // Get QuantityList specific render values
    this.lineBehaviour = JSON.parse(this.layoutControl.parseRV('LineBehaviour', QuantityList1LineBehaviour.Add));
    this.quantityScanEnabled = JSON.parse(this.layoutControl.parseRV('QuantityScanEnabled', false));
    this.quantityScanBehaviour = JSON.parse(this.layoutControl.parseRV('QuantityScanBehaviour', QuantityList1ScanBehaviour.None));
    this.setComplexValueName = this.layoutControl.parseRV('SetComplexValueName', null);
    this.setComplexTriggerValueName = this.layoutControl.parseRV('SetComplexTriggerValueName', null);
    this.useUpdate = this.layoutControl.parseRV('UseUpdate', false);
    if (!this.setComplexValueName) {
      this.showMissingFieldNotification('SetComplexValueName');
      return;
    }
    // Get list definition (for the GRID CSS)
    this.listDefinition = JSON.parse(this.layoutControl.parseRV('QuantityListJson', null));
    if (!this.listDefinition) {
      this.showMissingFieldNotification('QuantityListJson');
      return;
    }

    if (this.listDefinition.style?.minHeight) {
      // FX3 printer old chrome webview doesn't support CSS max()...so we have to try to calculate it in javascript...
      // `max(${this.listDefinition.style.minHeight}, ${minOuterRowHeight}rem)`;
      this.rowMinHeight = this.getMinRowHeight(this.listDefinition.style.minHeight, 0);
      delete this.listDefinition.style?.minHeight;
    }

    // Get the designStyleMapping
    const designStyleMappingEnabled = this.layoutControl.parseRV('DesignStyleMapping', false);
    if (designStyleMappingEnabled) {
      this.designStyleMappings = JSON.parse(this.layoutControl.parseRV('DesignStyleMappingFilterStringJson', '[]'));
    }

    this.headerDefinition = this.getItemDesignStyle(this.listDefinition.header);
    this.headerAddToIndex = !!this.headerDefinition ? 1 : 0;

    this.rowDefinition = (this.listDefinition.items || [])
    .map(x => this.getItemDesignStyle(x))
    .filter(x => x);

    // Get list data
    const setId = this.layoutControl.parseRV('Set');

    this.setDatasObjectPointers = Object.keys(this.layoutScreen.sets[setId]?.datas || {})
    .map((dataId: string) => {
      const setData = this.layoutScreen.sets[setId].datas[dataId];
      const data = this.layoutScreen.datas[setData.objectId];
      if (!setData.setComplexDataValues[this.setComplexValueName] && data) {
        setData.setComplexDataValues = Object.assign(
          {},
          setData.setComplexDataValues,
          {
            [this.setComplexValueName]: new RuntimeLayoutValue({
              valueJson: this.getQuantityChangeCorrespondingDataValue(data),
              valueTypeId: RuntimeLayoutValueType.Double,
            })
          }
        )
      }
      return setData;
    });

    this.groupData();

    this.sortData();

    this.numOfVisibleItems = this.populateListDataFromTo(
      0,
      ((this.listDefinition.screenRows || this.defaultScreenRows) * (this.listDefinition.screenCols || this.defaultScreenCols)) - 1,
    );
    this.doSummaryCalculationIfEnabled();
    this.cdr.markForCheck();

    setTimeout(() => {
      this.listReady = true;
      this.cdr.markForCheck();
    }, 10);
  }

  private showMissingFieldNotification(missingFieldName: string): void {
    this.notificationService.showNotification(new Notification({
      blocking: true,
      text: `${this.translateService.instant('Missing')} ${missingFieldName}!`,
      type: RuntimeLayoutNotifyType.Unknown
    }));
    this.cdr.markForCheck();
  }

  private groupData(): void {
    if (!this.layoutControl.parseRV('UIGrouping')) return;
    if (!this.layoutControl.parseRV('UIGroupingFieldSubMemberGuidId')) {
      this.showMissingFieldNotification('UIGroupingFieldSubMemberGuidId');
      return;
    }
    if (!this.layoutControl.parseRV('UIGroupingStylesGuidIds')) {
      this.showMissingFieldNotification('UIGroupingStylesGuidIds');
      return;
    }

    this.uiGroupingMap = {};
    const uiGroupingDesignStyleGuidId = this.layoutControl.parseRV('UIGroupingStylesGuidIds', '').split(',')[0];
    const uiGroupingFieldSubMemberGuidId = this.layoutControl.parseRV('UIGroupingFieldSubMemberGuidId');
    for (let i = 0; i < this.setDatasObjectPointers?.length; i++) {
      let data = this.layoutScreen.datas[this.setDatasObjectPointers[i].objectId];
      if (!data) data = new RuntimeLayoutData({ objectId: this.setDatasObjectPointers[i].objectId });

      const uiGroupingValue = data.parseRV(uiGroupingFieldSubMemberGuidId, '-');
      if (Object.values(this.uiGroupingMap).some(x => x.value == uiGroupingValue)) {
        this.uiGroupingMap[data.objectId] = { value: uiGroupingValue, designStyleGuidId: uiGroupingDesignStyleGuidId };
        continue;
      }

      this.uiGroupingMap[data.objectId] = { value: uiGroupingValue, designStyleGuidId: uiGroupingDesignStyleGuidId };
      const groupDataObjectPointer = new RuntimeLayoutSetData(this.setDatasObjectPointers[i]);
      (groupDataObjectPointer as any).$uiGrouping = uiGroupingValue;
      this.setDatasObjectPointers.splice(i, null, groupDataObjectPointer);
      i++;
    }
  }

  private sortData() {
    this.setDatasObjectPointers = (this.setDatasObjectPointers || []).sort((a, b) => {
      let sortByGroupResult;
      let sortByValueResult;

      const aGroup = this.uiGroupingMap?.[a.objectId] as any;
      const bGroup = this.uiGroupingMap?.[b.objectId] as any;
      if (typeof aGroup?.value === 'string') {
        sortByGroupResult = (aGroup?.value || '').localeCompare(bGroup?.value || '');
      } else {
        sortByGroupResult = (aGroup?.value - bGroup?.value);
      }

      if (!this.activeSort?.SortOrder) return sortByGroupResult; // -1 Desc, 0 None, 1 Asc

      const aValue = this.layoutScreen.datas[a.objectId]?.parseRV(this.activeSort.SolutionTypeSubVariableMemberGuidId);
      const bValue = this.layoutScreen.datas[b.objectId]?.parseRV(this.activeSort.SolutionTypeSubVariableMemberGuidId);
      if (typeof aValue === 'string') {
        sortByValueResult = (aValue || '').localeCompare(bValue || '') * (this.activeSort.SortOrder);
      } else {
        sortByValueResult = (aValue - bValue) * (this.activeSort.SortOrder);
      }

      return sortByGroupResult || sortByValueResult;
    });
  }

  private getDefaultFilter(): any {
    if (!this.filtersDefinition) return null;

    let item = this.filtersDefinition.find((i: any) => {
      return i.Default;
    });
    console.log(item);
    return item;
  }

  private getDefaultSort(): any {
    if (!this.sortsDefinition) return null;

    let item = this.sortsDefinition.find((i: any) => {
      return i.Default;
    });
    console.log(item);
    return item;
  }

  private populateListDataFromTo(startIndex: number, endIndex: number): number {
    for (let i = startIndex; i <= endIndex; i++) {
      if (i >= this.setDatasObjectPointers.length) break;

      let data = this.layoutScreen.datas[this.setDatasObjectPointers[i].objectId];
      if (!data) data = new RuntimeLayoutData({ objectId: this.setDatasObjectPointers[i].objectId });

      if ((this.setDatasObjectPointers[i] as any).$uiGrouping) {
        const groupData: any = new RuntimeLayoutData(data);
        groupData.$uiGrouping = (this.setDatasObjectPointers[i] as any).$uiGrouping;

        this.listData.push(groupData);
        endIndex++; // we don't count the group rows for the total on screen rows
      } else if (this.doesDataPassActiveFilter(data) && this.listData.indexOf(data) < 0) {
        this.listData.push(data);
      } else {
        endIndex++; // if one row doesn't pass the filter, we allow to check for 1 more
      }
    }

    for (let i = 0; i < this.listData.length; i++) {
      const data: any = this.listData[i];
      if (!data?.$uiGrouping) continue;

      const nextData: any = this.listData[i+1];
      const nextDataUiGrouping = this.uiGroupingMap[nextData?.objectId];
      if (data.$uiGrouping === nextDataUiGrouping?.value) continue;

      this.listData.splice(i, 1);
      i--;
    }

    return this.listData.length;
  }

  private doesDataPassActiveFilter(data: RuntimeLayoutData) {
    if (!this.activeFilter) return true;

    for (const include of this.activeFilter.Included || []) {
      const dataValue = data.parseRV(include.SolutionTypeSubVariableMemberGuidId);
      let filterValue = RuntimeLayoutValue.parse(include.Value);
      if (typeof dataValue === 'boolean') {
        filterValue = ['1', 'True', 'true'].indexOf(filterValue) >= 0 ? true
        : ['0', 'False', 'false'].indexOf(filterValue) >= 0 ? false
        : filterValue;
      }

      if (dataValue == filterValue) return true;
    }

    for (const exclude of this.activeFilter.Excluded || []) {
      const dataValue = data.parseRV(exclude.SolutionTypeSubVariableMemberGuidId);
      let filterValue = RuntimeLayoutValue.parse(exclude.Value);
      if (typeof dataValue === 'boolean') {
        filterValue = ['1', 'True', 'true'].indexOf(filterValue) >= 0 ? true
        : ['0', 'False', 'false'].indexOf(filterValue) >= 0 ? false
        : filterValue;
      }

      if (dataValue == filterValue) return false;
    }

    return this.activeFilter.Included?.length ? false : true;
  }

  private doSummaryCalculationIfEnabled(): void {
    this.uiSummaryValue = undefined;
    if (!this.layoutControl.parseRV('UISummary')) return;
    if (!this.layoutControl.parseRV('UISummaryFieldSubMemberGuidId')) {
      this.showMissingFieldNotification('UISummaryFieldSubMemberGuidId');
      return;
    }

    const uiSummaryFieldSubMemberGuidId = this.layoutControl.parseRV('UISummaryFieldSubMemberGuidId');
    const uiSummaryLocationEnum = this.layoutControl.parseRV('UISummaryLocation', List1DeviceControlUISummaryLocation.TopCenter);
    this.uiSummaryLocation = List1DeviceControlUISummaryLocation[uiSummaryLocationEnum].replace(/([A-Z])/g, ($1) => { return '-' + $1.toLowerCase(); }).substring(1);

    this.uiSummaryValue = 0.0;
    for (let setData of this.setDatasObjectPointers || []) {
      if ((setData as any).$uiGrouping) continue;

      let data = this.layoutScreen.datas[setData.objectId];
      if (!data) data = new RuntimeLayoutData({ objectId: setData.objectId });
      if (this.doesDataPassActiveFilter(data)) {
        this.uiSummaryValue += data.parseRV(uiSummaryFieldSubMemberGuidId, 0.0);
      }
    }
  }

  private getItemDesignStyle(item: any): RuntimeLayoutDesignStyle {
    if (!item) return undefined;

    const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
      return GuidUtils.isEqual((ld.designOriginalGuidId || ld.designGuidId), (item.design?.guidId || item.designGuidId));
    });
    if (!design) return undefined;

    const designStyle = design.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
      return GuidUtils.isEqual((lds.designStyleOriginalGuidId || lds.designStyleGuidId), (item.designStyle?.guidId || item.designStyleGuidId));
    });
    if (designStyle && !designStyle.style) {
      this.notificationService.showNotification(new Notification({
        blocking: true,
        title: this.translateService.instant('Empty DesignStyle!'),
        text: designStyle.designStyleGuidId,
        type: RuntimeLayoutNotifyType.Unknown
      }));
    }
    return designStyle;
  }

  getDesignStyleWithMapping(i: number, j: number): RuntimeLayoutDesignStyle | null {
    const itemData = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];

    if ((itemData as any)?.$uiGrouping) { // this means it's a UIGroup
      const designStyleGuidId = this.uiGroupingMap?.[itemData.objectId]?.designStyleGuidId;
      const design = (this.layoutDesigns || []).find((ld: RuntimeLayoutDesign) => {
        return ld.designStyles.find((lds: RuntimeLayoutDesignStyle) => {
          return GuidUtils.isEqual((lds.designStyleOriginalGuidId || lds.designStyleGuidId), designStyleGuidId);
        });
      });
      if (design) {
        return this.getItemDesignStyle({ designGuidId: design.designGuidId, designStyleGuidId: designStyleGuidId });
      }
    }

    if (!this.designStyleMappings?.length) return this.rowDefinition[j % this.listDefinition.rows];

    for (const designStyleMapping of this.designStyleMappings || []) {
      let groupResult = null;
      for (const filterGroup of designStyleMapping.filterStringFilter?.filterGroups || []) {
        let stepResult = this.evalFilterGroupResult(filterGroup, itemData);
        groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
      }
      if (groupResult) return this.getItemDesignStyle(designStyleMapping);
    }
    return this.rowDefinition[j % this.listDefinition.rows];
  }

  getItemData(item: DesignStyleJsonItem, i: number, j: number): RuntimeLayoutData {
    if (!this.listData?.length) return null;

    if (!item.field?.originalVariableGuidId) return this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];

    let data = null;
    if (item.field?.subVariableMemberGuidId) {
      const valueRaw = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)].values[item.field.subVariableMemberGuidId];
      if (valueRaw?.extendedValueType === 'Resource') {
        data = Object.values(this.layoutScreen.datas).find(x => x.dataGuidId === valueRaw.parse());
      }
    }
    if (!data) {
      const variable = Object.values(this.layoutScreen.variables).find(x => x.originalVariableGuidId === item.field.originalVariableGuidId);
      if (variable) {
        data = this.layoutScreen.datas[variable.value];
      }
    }

    if (data?.isResource) {
      this.layoutResourceService.get(data.resourceGuidId, data.resourceTick)
      .pipe(
        mergeMap((resource: Resource) => {
          const blob: Blob = new Blob([resource.content], { type: resource.contentType });
          return BlobUtils.blobToDataURL(blob);
        })
      )
      .subscribe((dataUrl: string) => {
        this.resourceMap[data.resourceGuidId] = dataUrl;
        this.cdr.markForCheck();
      });
    }

    return data;
  }

  getItemFieldValue(item: DesignStyleJsonItem, i: number, j: number): string {
    if (!item.field) return item.valueIfNull || '';

    const data = this.listData[(i * this.listDefinition.rows) + (j + this.headerAddToIndex)];
    if (!Object.keys(data?.values || {}).length) return `[CORRUPT DATA ID: ${data.objectId}]`;

    const result = item.field.staticValue != null ? item.field.staticValue
    : item.field.textId ? this.layoutTexts[item.field.textId]?.text
    : data.parseRV(item.field.subVariableMemberGuidId || item.field.originalVariableGuidId);
    return result != null ? result : (item.valueIfNull || '');
  }

  getSetComplexDataValue(data: RuntimeLayoutData): string {
    if (!data) return '-';

    const objectId = data.objectId;
    const setId = this.layoutControl.parseRV('Set');
    const setData = this.layoutScreen.sets[setId]?.datas?.[objectId];
    if (!setData) return '-';

    const value = setData?.parseRV(this.setComplexValueName);
    return value != null ? value : '-';
  }

  setComplexDataValueAutoQuantityChange(data: RuntimeLayoutData): void {
    if (!data) return;

    this.vibrationService.vibrate();

    const setId = this.layoutControl.parseRV('Set');
    const setData = this.layoutScreen.sets[setId]?.datas?.[data.objectId];
    if (!setData) return;

    let currentValue = setData.parseRV(this.setComplexValueName);
    let updatedValue = currentValue != null ? currentValue : 0;
    updatedValue += this.lineBehaviour === QuantityList1LineBehaviour.Remove ? -1 : 1;
    if (updatedValue < 0) {
      updatedValue = 0;
      this.notificationService.showNotification(new Notification({
        blocking: false,
        title: this.translateService.instant('Verification'),
        text: this.layoutControl.parseRV('QuantityScanNoQuantityMessage') || this.translateService.instant('No quantity!'),
        type: RuntimeLayoutNotifyType.VerificationAlert
      }));
      this.cdr.markForCheck();
      return;
    }

    if (setData.setComplexDataValues[this.setComplexValueName]) {
      setData.setComplexDataValues[this.setComplexValueName].valueJson = updatedValue.toString();
    } else {
      setData.setComplexDataValues = Object.assign(
        {},
        setData.setComplexDataValues,
        {
          [this.setComplexValueName]: new RuntimeLayoutValue({
            valueJson: updatedValue.toString(),
            valueTypeId: RuntimeLayoutValueType.Double,
          })
        }
      )
    }
    (setData as any).$isDirty = true;

    this.updateQuantityChangeCorrespondingDataValue(data, updatedValue);
    this.cdr.markForCheck();

    const triggerValue = setData.parseRV(this.setComplexTriggerValueName);
    if (triggerValue != null && triggerValue == updatedValue) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerPortEvent('QuantityUpdate');
        }
      );
      return;
    }

    const allComplete = !Object.values(this.layoutScreen.sets[setId]?.datas || {}).some((sd: RuntimeLayoutSetData) => {
      return sd.parseRV(this.setComplexValueName, 0);
    });
    if (allComplete) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerPortEvent('AllComplete');
        }
      );
    }
  }

  setComplexDataValueManualQuantityChange(data: RuntimeLayoutData, discard?: boolean): void {
    const setId = this.layoutControl.parseRV('Set');
    const setData = this.layoutScreen.sets[setId]?.datas?.[data.objectId];
    if (!setData) return;

    if (this.keyboardService.isVisible()) {
      if (discard) {
        setData.setComplexDataValues[this.setComplexValueName].valueJson = this.getQuantityChangeCorrespondingDataValue(data);
      } else {
        (setData as any).$isDirty = true;
      }
      this.updateQuantityChangeCorrespondingDataValue(data, setData.setComplexDataValues[this.setComplexValueName].valueJson);

      this.activeKeyboardForData = undefined;

      this.layoutScreen.backButton = this.originalBackButton;
      this.layoutScreen.forwardButton = this.originalForwardButton;
      this.layoutScreen.renderValues.ButtonsType = new RuntimeLayoutValue({ valueJson: this.originalButtonsType.toString(), valueTypeId: RuntimeLayoutValueType.Int });
      this.layoutScreenChange.emit(this.layoutScreen);
      this.keyboardService.hide();

      this.cdr.markForCheck();
      return;
    }

    this.activeKeyboardForData = data;

    this.layoutScreen.backButton = true;
    this.layoutScreen.forwardButton = true;
    this.layoutScreen.renderValues.ButtonsType = new RuntimeLayoutValue({ valueJson: '1', valueTypeId: RuntimeLayoutValueType.Int });
    this.layoutScreenChange.emit(this.layoutScreen);

    let newValue = '';
    this.keyboardService.show(
      KeyboardType.Numeric,
      false,
      null,
      (key: string) => {
        if (key === 'Backspace') {
          if (newValue.length > 0) newValue = newValue.substring(0, newValue.length - 1);
        } else if (key !== '.' || newValue.indexOf('.') < 0) {
          newValue += key;
        }

        if (newValue.length > 0 && newValue.indexOf('.') === newValue.length - 1) return;

        setData.setComplexDataValues[this.setComplexValueName].valueJson = newValue.toString();
        this.cdr.markForCheck();
      }
    );
  }

  private getQuantityChangeCorrespondingDataValue(data: RuntimeLayoutData): any {
    const setComplexUpdateFieldSubMemberGuidId = this.layoutControl.parseRV('SetComplexUpdateFieldSubMemberGuidId', null);
    if (!setComplexUpdateFieldSubMemberGuidId) return;

    return data.parseRV(setComplexUpdateFieldSubMemberGuidId);
  }

  private updateQuantityChangeCorrespondingDataValue(data: RuntimeLayoutData, updatedValue: any): void {
    this.updateUpdateContext();

    const setComplexUpdateFieldSubMemberGuidId = this.layoutControl.parseRV('SetComplexUpdateFieldSubMemberGuidId', null);
    if (!setComplexUpdateFieldSubMemberGuidId) return; // this seems to be '00000000000000000000000000000000' when not set, so it won't ever return here...

    const value: RuntimeLayoutValue = data.values?.[setComplexUpdateFieldSubMemberGuidId];
    if (!value) return;

    value.valueJson = updatedValue.toString();

    this.listData = [];
    this.numOfVisibleItems = this.populateListDataFromTo(0, this.numOfVisibleItems);
    this.doSummaryCalculationIfEnabled();
    this.pipePureValueBusting++;

    setTimeout(() => {
      this.gridsterComponents.forEach(g => g.resize());
      this.cdr.markForCheck();
    }, 10);
  }

  private evalFilterGroupResult(outterfilterGroup: any, itemData: RuntimeLayoutData): boolean {
    if (!outterfilterGroup.filter?.filterGroups?.length) {
      return this.evalFilterResult(outterfilterGroup.filter, itemData);
    }

    let groupResult = null;
    for (const filterGroup of outterfilterGroup.filter?.filterGroups || []) {
      let stepResult = this.evalFilterResult(filterGroup.filter, itemData);
      groupResult = groupResult === null ? stepResult : filterGroup.operator === 1 ? groupResult && stepResult : groupResult || stepResult;
    }
    return groupResult;
  }

  private evalFilterResult(filter: any, itemData: RuntimeLayoutData) {
    if (!filter || !itemData?.values) return null;

    let stepResult = null;
    const filterValue = itemData.parseRV(GuidUtils.clean(filter.member.guidId));
    switch (filter.operator) {
      case '=':
        stepResult = filter.value == filterValue;
        break;
      case '!=':
        stepResult = filter.value != filterValue;
        break;
      case '<':
        stepResult = filter.value < filterValue;
        break;
      case '<=':
        stepResult = filter.value <= filterValue;
        break;
      case '>':
        stepResult = filter.value > filterValue;
        break;
      case '>=':
        stepResult = filter.value >= filterValue;
        break;
    }
    return stepResult;
  }

  protected advanceCallback(scan: Scan, plugin?: BasePlugin) {
    const quantityScanBehaviour = JSON.parse(this.layoutControl.parseRV('QuantityScanBehaviour', QuantityList1ScanBehaviour.None));
    const quantityScanEnabled = JSON.parse(this.layoutControl.parseRV('QuantityScanEnabled', false));
    const scanItemSearchMemberGuidIds = this.layoutControl.parseRV('ScanItemSearchMemberGuidIds', '');
    if (!quantityScanEnabled || !scanItemSearchMemberGuidIds) return super.advanceCallback(scan, plugin);

    // try to search the list items to see if we get a match...
    for (let setData of this.setDatasObjectPointers || []) {
      let data = this.layoutScreen.datas[setData.objectId];
      for (const searchMemberGuidId of scanItemSearchMemberGuidIds.split(',') || []) {
        if (data.values[searchMemberGuidId]?.parse() != scan.value) continue;

        this.ngZone.run(() => {
          if (plugin) plugin.stop();
          if (quantityScanBehaviour === QuantityList1ScanBehaviour.None) { // select item
            LogUtils.log('scanner.advanceCallback() - list item click:', data);
            this.itemClick(data, null, 'Item');
          } else { // quantityChange
            LogUtils.log('scanner.advanceCallback() - list item quantityChange:', data);
            this.setComplexDataValueAutoQuantityChange(data);
          }
        });
        return;
      }
    }

    // scanned item was not found, so, either trigger Update port, or just do normal scan event
    if ((this.updateContextArray || []).length > 0) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          super.advanceCallback(scan, plugin);
        }
      );
    } else {
      super.advanceCallback(scan, plugin);
    }
  }

  private clearSetDatasIsDirty() {
    for (let i = 0; i < this.setDatasObjectPointers?.length; i++) {
      let setData: RuntimeLayoutSetData = this.setDatasObjectPointers[i];
      delete (setData as any).$isDirty;
    }
  }

  private updateUpdateContext() {
    this.updateContextArray = [];
    for (let i = 0; i < this.setDatasObjectPointers?.length; i++) {
      let setData: RuntimeLayoutSetData = this.setDatasObjectPointers[i];
      if (!(setData as any).$isDirty) continue;
      let data: RuntimeLayoutData = this.layoutScreen.datas[this.setDatasObjectPointers[i].objectId];
      if (!data) continue;

      const values = {
        [this.setComplexValueName]: setData.parseRV(this.setComplexValueName),
      };
      if (this.setComplexTriggerValueName) values[this.setComplexTriggerValueName] = setData.parseRV(this.setComplexTriggerValueName);

      this.updateContextArray.push({
        objectId: data.objectId,
        runtimeObjectId: data.runtimeDataObjectId,
        values: values,
      });
    }
  }

  backButtonOverride(): boolean {
    if (this.activeKeyboardForData && this.keyboardService.isVisible()) {
      this.setComplexDataValueManualQuantityChange(this.activeKeyboardForData, true);
      return true;
    }

    if ((this.updateContextArray || []).length > 0 && !this.ignoreButtonOverride) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.BackButton });
        }
      );
      return true;
    }

    this.ignoreButtonOverride = false;
    return false;
  }

  forwardButtonOverride(): boolean {
    if (this.activeKeyboardForData && this.keyboardService.isVisible()) {
      this.setComplexDataValueManualQuantityChange(this.activeKeyboardForData, false);
      return true;
    }

    if ((this.updateContextArray || []).length > 0 && !this.ignoreButtonOverride) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this.triggerEvent.emit({ platformObjectType: RuntimeLayoutEventPlatformObjectType.ForwardButton });
        }
      );
      return true;
    }

    this.ignoreButtonOverride = false;
    return false;
  }

  preActionTrigger(): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      if ((this.updateContextArray || []).length > 0) {
        this.triggerPortEvent(
          'Update',
          () => {
            this.clearSetDatasIsDirty();
            observer.next(null);
            observer.complete();
          }
        );
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  itemClick(data: RuntimeLayoutData, designStyle?: RuntimeLayoutDesignStyle, portName?: string) {
    if (Object.keys(data?.values || {}).length === 0) return;
    if (designStyle?.notClickable) return;

    this.vibrationService.vibrate();
    this.clickedData = data;

    if ((this.updateContextArray || []).length > 0) {
      this.triggerPortEvent(
        'Update',
        () => {
          this.clearSetDatasIsDirty();
          this._itemClick(data, designStyle, portName);
        }
      );
      return;
    }

    this._itemClick(data, designStyle, portName);
  }

  private _itemClick(data: RuntimeLayoutData, designStyle?: RuntimeLayoutDesignStyle, portName?: string) {
    const grouping = this.layoutControl.parseRV('Grouping', false);

    const eventContextValues: any = {};
    eventContextValues['EventCode'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify('ListItem'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: portName ? JSON.stringify(portName)
      : grouping ? JSON.stringify('Items')
      : JSON.stringify('Item'),
      valueTypeId: RuntimeLayoutValueType.String
    });
    this.triggerEvent.emit({
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

  private triggerPortEvent(portName: 'AllComplete' | 'QuantityUpdate' | 'Update', callback?: () => void) {
    const eventContextValues: any = {};
    eventContextValues['PortName'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(portName),
      valueTypeId: RuntimeLayoutValueType.String
    });

    if (portName === 'Update' && !this.useUpdate) {
      this.ignoreButtonOverride = true;
      if (callback) callback();
      return;
    }

    this.triggerEvent.emit({
      callback: (result: boolean) => {
        if (!result) {
          this.updateUpdateContext();
          return;
        }

        if (callback) callback();
      },
      eventContext: new RuntimeLayoutEventContext({ values: eventContextValues }),
      platformObjectType: RuntimeLayoutEventPlatformObjectType.Unknown,
    });
  }

  getControlContext(): DictString<RuntimeLayoutValue> {
    const setId = this.layoutControl.parseRV('Set');
    const set = this.layoutScreen.sets[setId];
    let context: any = null;

    if ((this.updateContextArray || []).length > 0) {
      context = {};
      context['SetObjectId'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(set?.objectId || 0),
        valueTypeId: RuntimeLayoutValueType.String
      });
      context['RuntimeSetObjectId'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(set?.runtimeSetObjectId || 0),
        valueTypeId: RuntimeLayoutValueType.String
      });
      context['Update'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.updateContextArray)),
        valueTypeId: RuntimeLayoutValueType.String
      });
      this.updateContextArray = null;
      if (this.useUpdate) return context;
    }

    if (!this.clickedData) return context;

    context = context || {};
    context['SourceRuntimeObjectId'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(set?.runtimeSetObjectId || 0),
      valueTypeId: RuntimeLayoutValueType.Int
    });
    context['ItemRuntimeObjectId'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(this.clickedData.runtimeDataObjectId),
      valueTypeId: RuntimeLayoutValueType.Int
    });
    context['ItemGuidId'] = new RuntimeLayoutValue({
      valueJson: JSON.stringify(this.clickedData.dataGuidId),
      valueTypeId: RuntimeLayoutValueType.String
    });

    if (this.layoutControl?.parseRV('EventGps')) {
      context['EventGps'] = new RuntimeLayoutValue({
        valueJson: JSON.stringify(JSON.stringify(this.geolocationService.getLastKnownPosition())),
        valueTypeId: RuntimeLayoutValueType.String
      });
    }

    return context;
  }

  getNgForArray(repetitions: number) {
    if (repetitions > ~~repetitions) {
      repetitions = ~~repetitions + 1; // round up if required...
    }
    return Array(repetitions).fill(1).map((x,i) => i + 1);
  }

  doInfinite(event: any) {
    setTimeout(() => {
      this.numOfVisibleItems = this.populateListDataFromTo(
        this.numOfVisibleItems,
        this.numOfVisibleItems + (this.listDefinition.screenRows || this.defaultScreenRows) - 1
      );

      event.target.complete();
      this.cdr.markForCheck();
    }, 10);
  }

  getMinRowHeight(rowHeightPercentage: number | string, minRem: number | string) {
    return Math.max(
      this.convertRowHeightPercentageToPixels(rowHeightPercentage),
      this.convertRemToPixels(minRem),
    ) + 'px'
  }

  private convertRowHeightPercentageToPixels(rowHeightPercentage: number | string) {
    return (parseFloat(rowHeightPercentage.toString()) / 100) * parseFloat(getComputedStyle(this.el.nativeElement).height);
  }

  private convertRemToPixels(rem: number | string) {
    return parseFloat(rem.toString()) * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

}

