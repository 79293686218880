import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DictNumber, RuntimeLayout, RuntimeLayoutDesign, RuntimeLayoutEventContext, RuntimeLayoutSnapshot, RuntimeLayoutText } from 'src/app/shared/models';
import { RuntimeLayoutSmartImage } from 'src/app/shared/models/runtime-layout/smart-image/runtime-layout-smart-image.model';
import { AppService } from 'src/app/shared/services';
import { ScreenBaseComponent } from '../base/screen-base.component';
import { Observable, of } from 'rxjs';



@Component({
  selector: 'lc-screen-dual1',
  templateUrl: 'screen-dual1.component.html',
  styleUrls: ['./screen-dual1.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenDual1Component extends ScreenBaseComponent {

  @Input() layout: RuntimeLayout;
  @Input() layoutDesigns: RuntimeLayoutDesign[];
  @Input() layoutSnapshot: RuntimeLayoutSnapshot;
  @Input() layoutTexts: DictNumber<RuntimeLayoutText>;
  @Input() smartImages: RuntimeLayoutSmartImage[];

  isConnectionToServerActive: boolean;

  constructor(
    private appService: AppService,
    cdr: ChangeDetectorRef,
  ) {
    super(cdr);

    this.subscriptions.push(
      this.appService.isConnectionToServerActive()
      .subscribe((isConnectionToServerActive: boolean) => {
        this.isConnectionToServerActive = isConnectionToServerActive;
        this.cdr.markForCheck();
      })
    );
  }

  preActionTrigger(): Observable<void> {
    return of(null);
  }

  getControlsContext(): DictNumber<RuntimeLayoutEventContext> {
    return null;
  }

  backButtonOverride(): boolean {
    return false;
  }

  forwardButtonOverride(): boolean {
    return false;
  }

}

